import {GetStaticProps} from "next";
import React from "react";
import CommonHOCComponent from "../_hoc/Common";
import GetAppVersion from "../_hoc/GetAppVersion";
import GetStaticPageData from "../_hoc/GetStaticPageData";
import IndexPageIndiaV2 from "../Layouts/India/IndexPageIndiaV2";
import {fetchDeviceArchitechtureMapping, getData} from "../lib/network/api";
import {ICommonData, IDeviceArchitechture} from "../lib/types/common";
import {IHomeApiData, IHomeBannerTestingData, IHomePageLanguageData} from "../lib/types/home";
import {IHomePageUSLanguageData, IHomeUSApiData} from "../lib/types/home.us";
import {ABTestingData, BaseProps} from "../lib/types/Misc.type";
import {COMMON, HOME_PAGE, HOME_PAGE_URL, homePagesWithLocale, VariantA} from "../lib/utils/constants/GlobalConstants";
import {COUNTRY, LANGUAGE_COUNTRY} from "../lib/utils/constants/LocaleAndCountryConstants";
import {
    combineHOC,
    getCountryFromLocale,
    getLangugageToggleData,
    getNewHomePageABTestingData,
    iosCtaABTestingData,
    showToggle
} from "../lib/utils/constants/PagesConstants";
import {getWebsiteEnvironment, WINZO_DOT_APP, WINZO_GLOBAL_URL} from "../lib/utils/constants/EnvironmentConstants";


type Props = {
    apiData: IHomeApiData | IHomeUSApiData | any
    autoScrollToElement: () => void
    url: string
    installStepContainer: React.RefObject<HTMLElement>
    downloadUrl: string
    androidUrl: string
    abTestingData: ABTestingData
} & BaseProps

function GenericHomePage(props: Props) {
    return <IndexPageIndiaV2 {...props} />
}

export const getStaticProps: GetStaticProps = async ({locale}) => {
    const pageName: string = HOME_PAGE_URL;
    const pageSegment: string = '';
    const jsonName: string = HOME_PAGE;
    const languageToggleData = getLangugageToggleData(homePagesWithLocale, pageName, locale!);

    const getAdditionalData = async (data: IHomePageLanguageData | IHomePageUSLanguageData, commonData: ICommonData,
                                     abTestingData: ABTestingData) => {
        let showLanguageToggle = true
        if (locale === LANGUAGE_COUNTRY.PT_BR && getWebsiteEnvironment().baseUrl === WINZO_GLOBAL_URL) {
            data = await getData(locale, HOME_PAGE + "-winzoglobal")
            commonData = await getData<ICommonData>(locale, COMMON + "-winzoglobal");
            showLanguageToggle = false
        } else if (locale === LANGUAGE_COUNTRY.EN_IN && getWebsiteEnvironment().baseUrl === WINZO_DOT_APP) {
            data = await getData(locale, HOME_PAGE + "-winzo-dot-app")
            commonData = await getData<ICommonData>(locale, COMMON + "-winzo-dot-app");
            showLanguageToggle = false
        }
        let variant = VariantA
        let newHomePageABTestingData: IHomeBannerTestingData | null = getNewHomePageABTestingData(abTestingData);
        if (newHomePageABTestingData && newHomePageABTestingData.executeTesting.includes("/")) {
            variant = newHomePageABTestingData.variant;
        }
        const iosCtaData: IHomeBannerTestingData | null = iosCtaABTestingData(abTestingData)
        let showOfferPopup: boolean = getCountryFromLocale(locale) === COUNTRY.INDIA && showToggle();
        return {
            showLanguageToggle,
            variant,
            showOfferPopup,
            iosCtaData,
            commonData,
            data,
            abTestingData
        };
    }

    return await GetStaticPageData<IHomePageLanguageData | IHomePageUSLanguageData>(getData, jsonName, pageName, pageSegment,
        locale, languageToggleData, 15, getAdditionalData);
};

export default combineHOC(CommonHOCComponent, GetAppVersion)(GenericHomePage);

